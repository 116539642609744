<template>
<div class="modal fade" id="creaditModal" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body modal-body-credit">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h3 class="title-main bold-weight mb-3">Finanzierungsbeispiel</h3>
                <div class="title-secondary mb-2">Beispiel:</div>
                <div class="modal-body-credit__list">
                    <div class="row mb-1">
                        <div class="col-sm-6 col-8"><div class="title-secondary text-muted">Gesamsumme:</div></div>
                        <div class="col-sm-6 col-4"><div class="title-secondary">{{ $filters.currency(totalPrice) }}:</div></div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-sm-6 col-8"><div class="title-secondary text-muted">Laufzeit:</div></div>
                        <div class="col-sm-6 col-4"><div class="title-secondary">{{ month }} Monate</div></div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-sm-6 col-8"><div class="title-secondary text-muted">Sie zahlen monatlich nur:</div></div>
                        <div class="col-sm-6 col-4"><div class="title-secondary">{{ $filters.currency(creditSumm) }}</div></div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-sm-6 col-8"><div class="title-secondary text-muted">Gesamtkosten:</div></div>
                        <div class="col-sm-6 col-4"><div class="title-secondary">{{ $filters.currency(creditTotal) }}</div></div>
                    </div>
                </div>
                <div class="title-secondary mt-3">Haben Sie Interesse an unseren Finanzierungsangebot?</div>
                <div class="row mt-3 mb-3">
                    <div class="col-md-6 mb-2">
                        <div class="title-secondary bold-weight">Dann Fragen Sie einfach an:</div>
                    </div>
                    <div class="col-md-6 contact-box">
                        <a href="" class="contact-box__link mb-2"><i class="fas fa-phone-alt"></i> 030 / 31007700</a>
                        <a href="" class="contact-box__link"><i class="fas fa-envelope"></i> info@loewe-zaun.de</a>
                    </div>
                </div>
                <div class="text-muted small-size-14 dynamic-content">
                    <p>Für dieses unverbindliche Berechnungsbeispiel wird der Zinssatz von 1,75% effekt. Jahreszins verwendet.</p>
                    <p>Ihr tatsächlicher Zinssatz kann davon abweichen.</p>
                    <p>Bitte beachten Sie unser 2/3 Beispiel gem. § 6a PAngV.</p>
                    <p>Fester Sollzinssatz zwischen 1,73% und 8,15%, effektiver Jahreszins zwischen 1,75% und 8,45%,
                        Nettodarlehensbetrag von 1.500€ bis 80.000€, Gesamtbetrag von 1.514,47€ bis 99.373,61€,
                        monatl. Raten von 50,00€ bis 6.921,50€, 12 bis 96 Raten, Laufzeit von 13 bis 98 Monaten.
                        Bonität vorausgesetzt.</p>
                    <p>TARGOBANK AG, Kasernenstraße 10, 40213 Düsseldorf. 2/3</p>
                    <p>Beispiel: Nettodarlehensbetrag von 13.500€, Gesamtbetrag 14.983,67€,</p>
                    <p>monatl. Raten 208,10€, 72 Raten, Laufzeit 73 Monate, fester Sollzinssatz 3,40%, effektiver Jahreszins 3,45%.</p>
                </div>
            </div>
        </div>
  </div>
</div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    const percent = ref(5)
    const month = ref(48)

    const price = computed(() => {
      return store.getters['configurator/price']
    })
    const totalPrice = computed(() => {
      return Math.ceil(price.value.total)
    })
    const creditSumm = computed(() => {
      const total = totalPrice.value
      const creditTotal = total * percent.value / 100
      const totalSumm = total + creditTotal
      const monthSumm = totalSumm / month.value
      return monthSumm
    })
    const creditTotal = computed(() => {
      const total = totalPrice.value * percent.value / 100
      const totalSumm = totalPrice.value + total
      return totalSumm
    })
    return {
      month,
      totalPrice,
      creditSumm,
      creditTotal
    }
  }
}
</script>
